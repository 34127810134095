import React from 'react'
import styled from 'styled-components'

import Layout from '../layouts'
import SEO from '../components/seo'
import BG from '../components/bg'

import Tick from '../images/tick.png'
import Cross from '../images/cross.png'
import RSOT from '../images/rissotto3dtrimmed.png'
import Compound from '../images/compound.png'
import AAve from '../images/aave.png'
import Atomic from '../images/atomic.png'

const StyledCompare = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
  padding: 0 2rem;
  padding-bottom: 4rem;
  margin-bottom: 4rem;
  padding-top: 2rem;

  border-bottom: 1px solid ${({ theme }) => theme.colors.grey2};

  @media (max-width: 960px) {
    flex-direction: column;
    grid-template-columns: 1fr;
    margin-top: 0rem;
    padding-top: 1rem;
  }

  @media (max-width: 560px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

const StyledSectionFlex = styled.div`
  padding: 0 0 4rem 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  // max-width: 960px;
  // margin-left: 5rem;
  text-align: center;
  @media (max-width: 1024px) {
    padding: 1rem;
    margin-top: 0rem;
    // flex-direction: ${({ wrapSmall }) => (!wrapSmall ? 'row' : 'column')};
  }
  @media (max-width: 960px) {
    padding: 1rem;
    margin-left: 0;
    margin-top: 0rem;
    width: 100%;
    // flex-direction: column;
    justify-content: flex-start;
    overflow-x: auto;
  }
  @media (max-width: 560px) {
    padding-left: 0.1rem;
    padding-right: 0.1rem;
    margin-left: 0;
    margin-top: 0rem;
    width: 100%;
    // flex-direction: column;
    justify-content: flex-start;
  }
  h1,                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
  h2 {
    max-width: 650px;
  }
  p {
    /* margin-bottom: 0.5rem; */
    max-width: 650px;
  }
`

const StyledSectionTitle = styled.h3`
  max-width: 960px;
  line-height: 140%;
  font-size: 32px;
  @media (max-width: 640px) {
    text-align: left;
  }
`

const StyledHeaderImage = styled.img`
  // width: 100%;
  // max-width: 120px;
  margin-bottom: 0.5rem;
  background-color: none;
  border-radius: 6px;
  max-height: 40px;
  max-width: 120px;
`

const StyledImage = styled.img`
  margin-bottom: 0;
  background-color: none;
  border-radius: 6px;
  max-width: 30px;
`

const StyledImage2 = styled.img`
  margin-bottom: 0;
  background-color: none;
  border-radius: 6px;
  max-width: 25px;
`

const StyledTable = styled.table`
  line-height: normal;

  th, td {
    padding: 0 0.1rem;
  }

  th:not(:first-child) {
    width: 20px;
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
  }

  td:first-child {
    border-right: 1px solid #666;
    padding-left: 12px;
  }

  td:not(:first-child) {
    text-align: center;
  }

  tbody tr:nth-child(odd) {
    background: #eee;
  }
`

const Compare = props => {
  return (
    <Layout path={props.location.pathname}>
      <BG />
    
      <SEO title="Compare" path={props.location.pathname} />
  
      <StyledCompare>
        <StyledSectionTitle>
          Competitive Advantage
        </StyledSectionTitle>
        <StyledSectionFlex>
          <StyledTable border='1'>
            <thead>
                <th></th>
                <th><StyledHeaderImage src={RSOT} /></th>
                <th><StyledHeaderImage src={Compound} /></th>
                <th><StyledHeaderImage src={AAve} /></th>
                <th><StyledHeaderImage src={Atomic} /></th>
            </thead>
            <tbody>
                <tr>
                    <td>COLLATERALISED (PLEDGED) LOANS</td>
                    <td><StyledImage src={Tick} /></td>
                    <td><StyledImage src={Tick} /></td>
                    <td><StyledImage src={Tick} />
                        <div style={{ fontSize: '10px' }}>
                            (BTC used as collaterals)
                        </div>
                    </td>
                    <td><StyledImage2 src={Cross} /></td>
                </tr>
                <tr>
                    <td>UN-COLLATERALISED (UNPLEDGED) LOANS</td>
                    <td><StyledImage src={Tick} /></td>
                    <td><StyledImage2 src={Cross} /></td>
                    <td><StyledImage2 src={Cross} /></td>
                    <td><StyledImage src={Tick} /></td>
             </tr>
                <tr>
                    <td>LP STAKING</td>
                    <td><StyledImage src={Tick} /></td>
                    <td><StyledImage2 src={Cross} /></td>
                    <td><StyledImage2 src={Cross} /></td>
                    <td><StyledImage src={Tick} /></td>
                </tr>
                <tr>
                    <td>DAO</td>
                    <td><StyledImage src={Tick} /></td>
                    <td><StyledImage src={Tick} /></td>
                    <td><StyledImage2 src={Cross} /></td>
                    <td><StyledImage src={Tick} /></td>
                </tr>
                <tr>
                    <td>TOKEN BURNING</td>
                    <td><StyledImage src={Tick} /></td>
                    <td><StyledImage src={Tick} /></td>
                    <td><StyledImage2 src={Cross} /></td>
                    <td><StyledImage src={Tick} /></td>
                </tr>
                <tr>
                    <td>YIELD FARMING</td>
                    <td><StyledImage src={Tick} /></td>
                    <td><StyledImage src={Tick} />
                        <div style={{ fontSize: '10px' }}>
                            (Liquidity mining)
                        </div></td>
                    <td><StyledImage2 src={Cross} /></td>
                    <td><StyledImage src={Tick} /></td>
                </tr>
                <tr>
                    <td>CHAIN INTEROPERABILITY</td>
                    <td><StyledImage src={Tick} /></td>
                    <td><StyledImage2 src={Cross} /></td>
                    <td><StyledImage src={Tick} /></td>
                    <td><StyledImage2 src={Cross} /></td>
                </tr>
                <tr>
                    <td>AI LEVERAGED TRADING</td>
                    <td><StyledImage src={Tick} /></td>
                    <td><StyledImage2 src={Cross} /></td>
                    <td><StyledImage2 src={Cross} /></td>
                    <td><StyledImage2 src={Cross} /></td>
                </tr>
            </tbody>
          </StyledTable>
        </StyledSectionFlex>
      </StyledCompare>
    </Layout>
  )
  
}

export default Compare